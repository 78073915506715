import React, { useEffect, useState } from "react";
import axios from 'axios';
import {useParams} from 'react-router-dom';

import LinkButton from "./subcomponents/LinkButton";
import dreamLinks from "../data/dream-menu";

//import "./Buildings.css";
import Loading from "./subcomponents/Loading";
import Meta from "./subcomponents/Meta";
import Oops from "./subcomponents/Oops";
// import Land from "./Land";

function numberWithCommas(x) {
  x = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x))
      x = x.replace(pattern, "$1,$2");
  return x;
}

function TableRow (props) {
  const {data, header} = props;
  //console.log(props);
  return (<tr>
    {header.map((col, i) => {         // map each item in the header
      //console.log("Data cell", i);
      if (data[col] === 999) {
        data[col] = "?"
      }
      //console.log (data[col], col);
      if (data[col] === undefined) {    // this data type does not exist, return an empty cell
        return <td key = {i}> </td>
      } else if (col === "unlock") {  // the unlock has special formating
        return <td key = {i}> 
          <div className="unlock-container">
            <img className="unlock-xp-icon" src="/images/dream/xp.png" alt={col}></img>
            <p className="unlock-xp">{data[col]}</p>
          </div>
        </td>
      } else if (typeof data[col] === "object") {
          const items = data[col];
          //console.log(items);
          return <td key = {i}> {
            Object.keys(items).map((key, i) => {
              items[key] === 999 && (items[key] = "?");
              return <React.Fragment key = {i} >
                {numberWithCommas(items[key])}<img className = "building-level" src = {"../../images/dream/"+ key +".png"} alt={key}></img><br />
              </React.Fragment>
            })
          }</td>
      } else {
        return <td key={i}> {data[col]}<img className = "building-level" src = {"../../images/dream/"+ col +".png"} alt={col}></img> </td>
      }
      

    })}
  </tr>)
}


// ************** Main Function *****************

export default function DreamBuildings ({match}) {

  // console.log("Dream Buildings - we got here")

  useEffect(()=>{
    const viewportmeta = document.querySelector('meta[name=viewport]');
    viewportmeta.setAttribute('content', "width=device-width, initial-scale=1.0");
  },[])


// **************************************************************************************************************************************  
  const [buildings, setBuildings] = useState();
  
  const {buildingUrl} = useParams();
  // console.log(match);

  let building;
  
  useEffect(()=>{
    axios.get('/api/dream/buildings')
      .then(res => {
        // console.log("results from database", res.data);
        setBuildings(res.data);
      });
  },[]);  

  // if (buildingUrl === "land") {
  //   return <Land/>
  // }
  
  
  if (buildings === undefined) {
    return<Loading text = "Loading buildings..." />
  } else {
    building = buildings.filter((building)=> building.link === buildingUrl)[0];   // grab the building from the parameter pass
    if (building === undefined) {
      return <Oops text = "That building doesn't seem to exist...  I'll take you back to buildings" link="/dream/buildings/"/>
    }
  }

  // console.log(building.variant);
  // let varBuildings;
  // if (building.variant !== undefined) {
  //   varBuildings = buildings.filter((varBuilding)=> varBuilding.variant === building.variant)
  // }
  // console.log("Var Buildings", varBuildings);

  let headerList = [];
  const header = building.levels[0];                                             // Grab the first building and see what is there
  Object.keys(header).map((key, i) => {
  //  console.log( typeof header[key] === "object" ? true : false );
    headerList.push(key);
    return null;
      
  });

  let produce;
  building.levels[0].produce && (produce = Object.keys(building.levels[0].produce)[0]);
    
  //console.log(produce);

  Meta(`${building.name} - bfmhfansite.com`, `All of the information you need for Big Farm Mobile Harvest's Dream${building.name}s.  Upgrade, yeild, best levels, etc. `);
  return (
    
    
    <div className = "building-tables" >
    {/* onKeyPress = {(e)=>console.log(e)}> */}
      
      {/* ********** Navbar (Quick Links) ************ */}
      <nav className="menu-content building-menu">
        {dreamLinks.map(link => {
          return(<LinkButton 
            key={link.key}
            name={link.name}
            link={link.link}
          />)
        })}
      </nav>
      {/* ********* Table Name ********** */}
      
      {/* <p className = 'blurb'>Buildings will take a while to update.  If the table is filled with question marks, it hasn't been updated yet.  If the cost has question marks, the resources to update hasn't been updated.  I don't think there are buckets or mallets, so costs need to be adjusted.  The big difference is the farmhouse; will take time to update.</p> */}

      <div style = {{"display": "block", "Postition": "relative"}} >
        <div className = "scroller-header" >
          {building.link !== 'land' && <div className="scroller-left"> <LinkButton key = "0" name = "previous" link = {"/dream/buildings/" + building.previous} /> </div>}
          {building.link !== 'land' && <div className="scroller-right"><LinkButton key = "10000" name = "next" link = {"/dream/buildings/" + building.next} /></div>}
          <img className="building-large" src={"../../images/dream/"+ building.link +".png"} alt={building.link}></img>
            
         
          
          <h2 className = "building-name"><span style={{color: "#D98D95"}}>Dream</span> {building.name}</h2> 
          {building.levels.length < 3 && <p className = "watermark-sm">bfmhfansite.com</p>}
          {/* <p className = {building.levels.length < 3 ? "watermark-sm" : "watermark"}>bfmhfansite.com</p>  */}
        
          {produce && <img className="building-produce" src={"../../images/dream/"+ produce +".png"} alt={produce}></img>}
        </div>
        
        
        {(building.link === "farmhouse" || building.link === "barn" || building.link === "silo" || building.link === "windmill"
          ) && <p className = "building-blurb">Rewards shown are collected from the quest book, shown here for clarity.</p>}
        
        
        {(building.link.includes("fertile") || building.link.includes("fancy")) && <p className = "building-blurb">Fancy and Fertile buildings go on sale almost weekly.  Best deals are 70-90% off during major holidays.</p>}  
        {building.link.includes("adventure") && <p className = "blurb">Buildings labeled 'Adventure' have multiple variants from different adventure farms, available when the farm is active.  They are the same with a different skin.</p>}

        
        {/* ********** Table ************** */}
        <div className = "building-table-overflow">

           
          <table id= "buildings" className = "building-table">
            <thead>
              <tr>
                {headerList.map((title, i) => {return <th key = {i}>{(title.charAt(0).toUpperCase() + title.slice(1)).replace("-", " ")}{(i === 0 && building.levels.length > 2) && <p className = "watermark-building-complex">bfmhfansite.com</p>}  </th>})}
              </tr>
            </thead>  
            <tbody className = "notranslate">
              {building.levels.map((level, i) => <TableRow key = {i} data = {level} header = {headerList} />)}
            </tbody>
          </table>
          
        </div>

      </div>  
    </div>
  )

}
