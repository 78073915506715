const dreamCalcData = [{
    ref: "farmhouse",
    multRef: false,
    image: "farmhouse-quest",
    active: false,
    isBull: false,
    bull: false,
    userLevel: 0,
    allowZero: false,
    userSpread: 3,
    multiplier: 1,
    isMultiplier: false,
    complete: false,
    cost: {}
  },
  {
    ref: "land",
    multRef: false,
    image: "expansion",
    active: true,
    isBull: false,
    bull: false,
    userLevel: 0,
    allowZero: false,
    userSpread: 3,
    multiplier: 1,
    isMultiplier: false,
    complete: false,
    cost: {}
  }
  ,{
    ref: "windmill",
    multRef: false,
    image: "windmill-simple",
    active: true,
    isBull: true,
    bull: false,
    userLevel: 0,
    allowZero: false,
    userSpread: 2,
    multiplier: 2,
    isMultiplier: 2,
    complete: true,
    cost: {}
  }
  ,{
    ref: "barn",
    multRef: false,
    image: "storage",
    active: true,
    isBull: false,
    bull: false,
    userLevel: 0,
    allowZero: false,
    userSpread: 2,
    multiplier: 1,
    isMultiplier: false,
    complete: false,
    cost: {}
  },
  {
    ref: "silo",
    multRef: false,
    image: "fertilizer",
    active: false,
    isBull: true,
    bull: false,
    userLevel: 0,
    allowZero: true,
    userSpread: 1,
    multiplier: 1,
    isMultiplier: false,
    complete: false,
    cost: {}
  },
 {
    ref: "field",
    multRef: false,
    image: "field-simple",
    active: true,
    isBull: true,
    bull: false,
    userLevel: 0,
    allowZero: true,
    userSpread: 1,
    multiplier: 2,
    isMultiplier: 9,
    complete: false,
    cost: {}
  },
  {
    ref: "house",
    multRef: false,
    image: "house-simple",
    active: false,
    isBull: true,
    bull: false,
    userLevel: 0,
    allowZero: true,
    userSpread: 1,
    multiplier: 2,
    isMultiplier: 20,
    complete: false,
    cost: {}
  },
  {
    ref: "apple-orchard",
    multRef: false,
    image: "apple",
    active: false,
    isBull: true,
    bull: false,
    userLevel: 0,
    allowZero: true,
    userSpread: 1,
    multiplier: 2,
    isMultiplier: 6,
    complete: false,
    cost: {}
  },
  
  {
    ref: "cherry-orchard",
    multRef: false,
    image: "cherry",
    active: false,
    isBull: true,
    bull: false,
    userLevel: 0,
    allowZero: true,
    userSpread: 1,
    multiplier: 1,
    isMultiplier: 6,
    complete: false,
    cost: {}
  },

  {
    ref: "almond-orchard",
    multRef: false,
    image: "almond",
    active: false,
    isBull: true,
    bull: false,
    userLevel: 0,
    allowZero: true,
    userSpread: 1,
    multiplier: 1,
    isMultiplier: 6,
    complete: false,
    cost: {}
  },

  {
    ref: "peach-orchard",
    multRef: false,
    image: "peach",
    active: false,
    isBull: true,
    bull: false,
    userLevel: 0,
    allowZero: true,
    userSpread: 1,
    multiplier: 1,
    isMultiplier: 6,
    complete: false,
    cost: {}
  },
 
  {
    ref: "beehive",
    multRef: false,
    image: "honey",
    active: false,
    isBull: true,
    bull: false,
    userLevel: -1,
    allowZero: true,
    userSpread: 2,
    multiplier: 1,
    isMultiplier: false,
    complete: false,
    cost: {}
  },
  {
    ref: "chicken-coop",
    multRef: false,
    image: "egg",
    active: false,
    isBull: true,
    bull: false,
    userLevel: 0,
    allowZero: true,
    userSpread: 1,
    multiplier: 1,
    isMultiplier: 6,
    complete: false,
    cost: {}
  },
  {
    ref: "pigsty",
    multRef: false,
    image: "pig",
    active: false,
    isBull: true,
    bull: false,
    userLevel: 0,
    allowZero: true,
    userSpread: 1,
    multiplier: 2,
    isMultiplier: 6,
    complete: false,
    cost: {}
  },
  {
    ref: "cowshed",
    multRef: false,
    image: "milk",
    active: false,
    isBull: true,
    bull: false,
    userLevel: 0,
    allowZero: true,
    userSpread: 1,
    multiplier: 1,
    isMultiplier: 6,
    complete: false,
    cost: {}
  },
  {
    ref: "goat-stable",
    multRef: false,
    image: "cheese",
    active: false,
    isBull: true,
    bull: false,
    userLevel: 0,
    allowZero: true,
    userSpread: 1,
    multiplier: 1,
    isMultiplier: 6,
    complete: false,
    cost: {}
  },
  {
    ref: "duck-coop",
    multRef: false,
    image: "feather",
    active: false,
    isBull: true,
    bull: false,
    userLevel: 0,
    allowZero: true,
    userSpread: 1,
    multiplier: 1,
    isMultiplier: 6,
    complete: false,
    cost: {}
  },
  {
    ref: "rabbit-hutch",
    multRef: false,
    image: "gray-wool",
    active: false,
    isBull: true,
    bull: false,
    userLevel: 0,
    allowZero: true,
    userSpread: 1,
    multiplier: 1,
    isMultiplier: 6,
    complete: false,
    cost: {}
  },
  {
    ref: "sheep-pen",
    multRef: false,
    image: "sheep-wool",
    active: false,
    isBull: true,
    bull: false,
    userLevel: 0,
    allowZero: true,
    userSpread: 1,
    multiplier: 1,
    isMultiplier: false,
    complete: false,
    cost: {}
  }
]

export const calc_date = "2025-02-22T00:22:18.053Z"

export const calc_crit_date = "2025-02-22T00:22:18.053Z"

export const update_reason = "Added bulldozing, stables and orchards.  Instructions below."

export default dreamCalcData;