import React, { useState, useEffect } from 'react';
import Meta from './Meta';
import parseBlog from './ParseBlog';
// import Loading from './Loading';
import AdBlog from '../Ads/AdBlog';

export default function InnerPage(props) {
  // console.log('Inner page', props.blog);

  const { blog } = props;
  const [index, setIndex] = useState(0);

  const [htmlSegments, setHtmlSegments] = useState([]);
  const [adPlacement, setAdPlacement] = useState([]);

  useEffect(() => {
    // algor to randomly pick ad spots

    if (htmlSegments.length !== 0) {
      // console.log("*********Start Placement")
      let chance = htmlSegments.length < 4 ? 0.75 : 0.5;
      let adSpots = [];

      for (let index = 0; index < htmlSegments.length - 1; index++) {
        // console.log("Chance", chance)

        if (Math.random() < chance) {
          adSpots.push(index);
          // console.log("Place ad and spot: ", index)
          if (chance === 1) chance = 0.75; // if placed after 2 fails, resets odds, instead of high odds.
          chance -= 0.25;
        } else {
          // console.log("Remove add at spot:", index)
          if (chance === 0) chance = 0.25;
          chance += 0.25;
        }
        // console.log("Current Placement", (adSpots.length)/(index+1))
      }
      setAdPlacement(adSpots);
      // console.log("Place ad algor")
      // console.log("IA?=", htmlSegments.length-1, adSpots)
    }
  }, [htmlSegments]);

  useEffect(() => {
    if (typeof blog.html === 'string') {
      // console.log("Run once - place ads")

      const blogLength = blog.html.length;
      const sectionLength =
        blogLength < 5000 ? 1000 : blogLength < 10000 ? 1250 : 1500;

      let currentSegment = '';
      let length = 0;
      let dividedHtml = [];

      const segments = blog.html.split('</p>'); // break up the blog by paragraphs
      segments.pop(); // remove the last empty segment
      segments.forEach((segment, i) => {
        currentSegment += segments[i] + '</p>';
        length += segment.length;

        if (length > sectionLength && segment.length >= 120) {
          // ever seciont, mark as spot to place ad
          dividedHtml.push(currentSegment);
          currentSegment = '';
          // adSpots.push(i);
          length = 0;
        }
      });

      // console.log("last segment", currentSegment)
      if (length < sectionLength * 0.7) {
        // remove the last ad spot if it is less than 75% of a section that follows
        const lastElement = dividedHtml.length - 1;
        dividedHtml[lastElement] += currentSegment;
      } else {
        dividedHtml.push(currentSegment);
      }

      setHtmlSegments(dividedHtml);
      // console.log("Segments", dividedHtml);
    }
    // eslint-disable-next-line
  }, []);

  function createMarkup(innerHtml) {
    return { __html: parseBlog(innerHtml) };
  }
  // console.log("html", htmlSegments)
  // console.log("Ad gaps", htmlSegments.length, adPlacement)

  // if (html === "" && typeof blog.html === "string") return <Loading text = "Loading Page..."/>

  Meta(blog.title, blog.excerpt);

  if (typeof blog.html === 'string') {
    return (
      <article className='blog-post'>
        <h2 className='blog-title'>{blog.title}</h2>
        {/* <div className = "blog-content" dangerouslySetInnerHTML={createMarkup(blog.html)}/> */}

        {htmlSegments.map((segment, i) => {
          return (
            <React.Fragment key={i}>
              <div
                className='blog-content'
                dangerouslySetInnerHTML={createMarkup(segment + '</p>')}
              ></div>
              {adPlacement.length !== 0 && adPlacement.includes(i) && (
                <AdBlog />
              )}
            </React.Fragment>
          );
        })}
        <div style={{ clear: 'both' }} />
      </article>
    );
  } else {
    return (
      <article className='blog-post'>
        <h2 className='blog-title'>{blog.title}</h2>
        <img
          className='guide-image'
          src={blog.cover}
          alt='Blog Preview'
        />
        <div className='scroller-header'>
          <button
            className='button scroller-left'
            onClick={index > 0 ? () => setIndex((p) => p - 1) : null}
          >
            <img
              className='button-icon'
              src={'/images/menu/previous.png'}
              alt='previous entry'
            />
          </button>
          <p className='sroller-right'>{index + 1 + '/' + blog.html.length}</p>
          <button
            className='button scroller-right'
            onClick={
              index + 1 < blog.html.length ? () => setIndex((p) => p + 1) : null
            }
          >
            <img
              className='button-icon'
              src={'/images/menu/next.png'}
              alt='previous entry'
            />
          </button>
        </div>
        <div className='card-preview'>
          <h2 className='title'>{blog.html[index].title}</h2>
          <div
            className='excerpt'
            dangerouslySetInnerHTML={createMarkup(blog.html[index].html)}
          />
        </div>
      </article>
    );
  }
}
